@use "sass:math"; @import "abstract";
[data-page="/error-404"] {
  @include scaledRem('font-size', 18, 12, 16);

  display: flex;
  padding-bottom: 0;
  text-align: center;

  @include mq($from: tablet) {
    justify-content: center;
  }

  @include mq($until: tablet) {
    @media screen and (min-device-height: 1100px) {
      padding-top: 135px;;
    }
  }

  .page-404__nav-list {
    @include mq($from: desktop) {
      column-gap: 24px;
      margin-left: 0;
    }
  }

  .nav-list {
    @include mq($until: desktop) {
      display: grid;
    }

    @include mq($from: tablet, $until: desktop) {
      align-self: center;
      grid-gap: 41px 66px;
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq($until: tablet) {
      grid-gap: 29px;
      grid-template-columns: 1fr;
      margin: 0 auto;
      max-width: mRem(130);

      @media screen and (min-device-height: 500px) and (max-device-height: 599px) {
        grid-gap: 24px;
      }
    }
  }

  .nav-list-item-link__icon {
    @include mq($until: tablet) {
      margin-right: 7px;
    }
  }

  .nav-list-item-link__text {
    @include mq($until: tablet) {
      padding-bottom: 3px;
    }
  }

  @media screen and (max-height: 600px) {
    padding-top: 0;
  }
}

.page-404 {
  @media screen and (min-height: 1100px) {
    padding-top: 135px;
  }

  @media screen and (min-height: 1200px) {
    padding-top: 228px;
  }

  @include mq($from: tablet, $until: desktop) {
    display: flex;
    flex-direction: column;
  }

  @include mq($until: tablet) {
    width: 100%;
  }

  &__logo {
    @include mqProp('margin-bottom', 51px, 33px);
    @include mqProp('margin-top', 42px, 33px);

    display: block;
    margin-left: auto;
    margin-right: auto;

    svg {
      @include mq($until: tablet) {
        width: 83px;
      }
    }
  }

  &__title {
    @include scaledRem('margin-bottom', 48, 30);

    @include mq($until: tablet) {
      margin-left: auto;
      margin-right: auto;

      @media screen and (max-device-height: 1099px) {
        max-width: mRem(210);
      }
    }
  }
}

.page-404-image-wrapper {
  position: fixed;

  @include mq($from: tablet) {
    bottom: 0;
    width: 1647px;
  }

  @include mq($from: desktop) {
    @media screen and (max-height: 699px) {
      height: 250px;
    }

    @media screen and (min-height: 700px) and  (max-height: 800px) {
      height: 350px;
    }
  }

  @include mq($from: tablet, $until: desktop) {
    @media screen and (max-height: 699px) {
      height: 210px;
    }

    @media screen and (min-height: 700px) and  (max-height: 800px) {
      height: 250px;
    }
  }

  @include mq($until: tablet) {
    bottom: 0;
    left: -163px;

    @media screen and (max-device-height: 1099px) {
      width: mRem(705);
    }

    @media screen and (min-device-height: 500px) and (max-device-height: 599px) {
      height: 72px;
    }

    @media screen and (min-device-height: 600px) and (max-device-height: 699px) {
      height: 125px;
    }

    @media screen and (min-device-height: 1100px) {
      width: mRem(990);
    }
  }
}
